import Banner from "../containers/WebApp/Government/Banner";
import Section from "../containers/WebApp/Government/Section";
import SEO from "../components/SEO";
import React from "react";

const Government = () => {
  return (
    <>
      <SEO title="Government" />
      <Banner />
      <Section />
    </>
  );
};

export default Government;
